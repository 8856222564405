import {createRouter, createWebHistory} from 'vue-router'

import LogIn from '@/views/auth/LogIn.vue'
import Register from '@/views/auth/Register.vue'
import Home from '@/views/HomeView/Home.vue'
import UserProfile from '@/views/profile/UserProfile.vue'
import EditProfile from '@/views/profile/editProfile/EditProfile.vue'
import EditContacts from '@/views/profile/editProfile/EditContacts.vue'
import EditPersonalData from '@/views/profile/editProfile/EditPersonalData.vue'
import CardHolder from '@/views/social/CardHolder.vue'
import Study from '@/views/study/Study.vue'
import MarketplaceCourses from '@/views/study/MarketplaceCourses.vue'
import NewsHome from '@/views/News/Home.vue'
import NewsId from '@/views/News/_id.vue'
import store from '@/store/store'
import MarketplaceProducts from '@/views/marketplace/MarketplaceProducts.vue'
import StudyCourse from '@/views/study/StudyCourse.vue'
import StudyModule from '@/views/study/StudyModule.vue'
import StudyLesson from '@/views/study/StudyLesson.vue'
import StudyCourseReview from '@/views/study/reviews/StudyCourseReview.vue'
import StudyCourseQuestions from '@/views/study/StudyCourseQuestions.vue'
import StudyTest from '@/views/study/StudyTest.vue'
import StudyModuleReview from '@/views/study/reviews/StudyModuleReview.vue'
import AnotherUserProfile from '@/views/profile/AnotherUserProfile.vue'
import UserChat from '@/views/chat/UserChat.vue'
import BaseLayout from '@/layouts/BaseLayout.vue'
import ChatList from '@/views/chat/ChatList.vue'
import SocialBrowsing from '@/views/social/SocialBrowsing.vue'
import EditDescription from '@/views/profile/editProfile/EditDescription.vue'
import EditProfessions from '@/views/profile/editProfile/EditProfessions.vue'
import EditNiches from '@/views/profile/editProfile/EditNiches.vue'
import EditEducations from '@/views/profile/editProfile/EditEducations.vue'
import EditEducation from '@/views/profile/editProfile/EditEducation.vue'
import EditAchievements from '@/views/profile/editProfile/EditAchievements.vue'
import EditAchievement from '@/views/profile/editProfile/EditAchievement.vue'
import EditProducts from '@/views/profile/editProfile/EditProducts.vue'
import EditProduct from '@/views/profile/editProfile/EditProduct.vue'
import EditLookingFor from '@/views/profile/editProfile/EditLookingFor.vue'
import MarketplaceCourseLanding from '@/views/study/MarketplaceCourseLanding.vue'
import BrowsingUserSearch from '@/views/social/BrowsingUserSearch.vue'
import UserSearch from '@/views/social/UserSearch.vue'
import CheckList from '@/views/profile/CheckList.vue'
import Onboarding from '@/views/profile/Onboarding.vue'
import Welcome from '@/views/auth/Welcome.vue'
import EventPage from '@/views/HomeView/EventPage.vue'
import Page404 from '@/views/system/Page404.vue'
import EventPreviewPage from '@/views/auth/preview/EventPreviewPage.vue'
import AnotherUsersProfilePreviewPage from '@/views/auth/preview/AnotherUsersProfilePreviewPage.vue'
import MarketplaceProductPreviewPage from '@/views/auth/preview/MarketplaceProductPreviewPage.vue'
import BuySubscription from "@/views/study/BuySubscription.vue"
import CoursePaid from "@/views/study/CoursePaid.vue";
import PrivacyPolicy from "@/views/policy/PrivacyPolicy.vue";
import InformationAdvertisingPolicy from "@/views/policy/InformationAdvertisingPolicy.vue";
import UserAgreement from "@/views/policy/UserAgreement.vue";
import PersonalDataProcessing from "@/views/policy/PersonalDataProcessing.vue";

const routes = [
  {
    path: '/',
    component: BaseLayout,
    meta: {
      layout: 'HomeLayout',
      navigationTab: 'home',
      requiresAuth: true,
    },
    children: [
      {path: '', name: 'Home', component: Home},
      {path: 'event/:eventId', name: 'Event', component: EventPage, props: true},
      {
        path: 'study',
        children: [
          {
            path: 'courses',
            children: [
              {
                path: '',
                name: 'MarketplaceCourses',
                component: MarketplaceCourses
              },
              {
                path: ':courseId/paid',
                name: 'CoursePaid',
                component: CoursePaid,
                meta: {layout: 'EmptyLayout'},
                props: true,
              },
              {
                path: ':courseId/about',
                name: 'MarketplaceCourseLanding',
                component: MarketplaceCourseLanding,
                props: true,
              },
              {
                path: 'subscription',
                name: 'BuySubscription',
                component: BuySubscription,
                props: true,
              },
            ]
          },
          {
            path: 'my-courses',
            children: [
              {path: '', name: 'StudyCourses', component: Study},
              {
                path: ':userCourseId',
                children: [
                  {path: '', name: 'StudyCourse', component: StudyCourse, props: true},
                  {
                    path: '',
                    name: 'CourseReview',
                    component: StudyCourseReview,
                    props: true,
                    meta: {layout: 'EmptyLayout'},
                  },
                  {
                    path: 'module',
                    children: [
                      {
                        path: ':moduleId',
                        children: [
                          {
                            path: '',
                            name: 'StudyModule',
                            component: StudyModule,
                            props: true
                          },
                          {
                            path: 'lesson',
                            children: [
                              {
                                path: ':lessonId',
                                children: [
                                  {
                                    path: '',
                                    name: 'StudyModuleLesson',
                                    component: StudyLesson,
                                    props: true
                                  },
                                  {
                                    path: 'test/:testId',
                                    name: 'StudyModuleTest',
                                    component: StudyTest,
                                    props: true
                                  },
                                ]
                              },
                            ]
                          },
                          {
                            path: 'review',
                            name: 'StudyModuleReview',
                            component: StudyModuleReview,
                            props: true,
                          },
                          {
                            path: 'questions',
                            name: 'StudyModuleQuestions',
                            component: StudyCourseQuestions,
                            props: true,
                          }
                        ]
                      },
                    ]
                  },
                  {
                    path: 'lesson',
                    children: [
                      {
                        path: ':lessonId',
                        children: [
                          {
                            path: '',
                            name: 'StudyCourseLesson',
                            component: StudyLesson,
                            props: true
                          },
                          {
                            path: 'test/:testId',
                            name: 'StudyCourseTest',
                            component: StudyTest,
                            props: true
                          },
                        ]
                      },
                    ]
                  },
                  {
                    path: 'review',
                    name: 'StudyCourseReview',
                    component: StudyCourseReview,
                    props: true,
                  },
                  {
                    path: 'questions',
                    name: 'StudyCourseQuestions',
                    component: StudyCourseQuestions,
                    props: true,
                  }
                ]
              },
            ],
          },
        ],
      },
      {
        path: 'browsing/:userId?',
        children: [
          {
            path: '',
            name: 'SocialBrowsing',
            component: SocialBrowsing,
            props: true,
          },
          {
            path: 'view',
            name: 'BrowsingUserView',
            component: BrowsingUserSearch,
            meta: {returnName: 'SocialBrowsing', isPage: true},
          },
          {
            path: 'search/:filter?',
            name: 'BrowsingUserSearch',
            props: true,
            meta: {returnName: 'BrowsingUserView', defaultSearch: 'global_result'},
            component: UserSearch,
          },
          {
            path: '/search/:userId/chat/:sentUID?',
            children: [
              {
                path: '',
                name: 'SearchUserChat',
                component: UserChat,
                props: true,
                meta: {returnName: 'BrowsingUserSearch', layout: 'EmptyLayout'},
              }
            ]
          },
          {
            path: 'profile/:userId',
            name: 'BrowsingUserProfile',
            component: AnotherUserProfile,
            props: true,
            meta: {returnName: 'BrowsingUserView', chatName: 'BrowsingUserChat'}
          },
          {
            path: 'chat/:sentUID?',
            children: [
              {
                path: '',
                name: 'BrowsingUserChat',
                component: UserChat,
                props: true,
                meta: {returnName: 'SocialBrowsing', layout: 'EmptyLayout'},
              }
            ]
          },
        ],
      },
      {
        path: 'marketplace',
        name: 'MarketplaceRoute',
        children: [
          {
            path: 'products/:productId?',
            children: [
              {
                path: '',
                name: 'MarketplaceProducts',
                component: MarketplaceProducts,
                props: true,
                meta: {chatName: 'MarketplaceProductUserChat', layout: 'EmptyHomeLayout'},
              },
              {
                path: 'user/:userId/chat/:sentUID?',
                name: 'MarketplaceProductUserChat',
                component: UserChat,
                props: true,
                meta: {returnName: 'MarketplaceProducts', layout: 'EmptyLayout'}
              }
            ],
          },
        ],
      },
      {
        path: 'my',
        children: [
          {
            path: 'profile',
            name: 'Profile',
            component: UserProfile
          },
          {
            path: 'profile/checklist',
            children: [
              {
                path: '',
                name: 'CheckList',
                component: CheckList,
                meta: {layout: 'EmptyLayout'},
              },
              {
                path: 'profile',
                name: 'CheckListEditProfile',
                component: EditProfile,
                meta: {returnName: 'CheckList', isCheckList: true}
              },
              {
                path: 'products',
                children: [
                  {
                    path: '',
                    name: 'CheckListEditProducts',
                    component: EditProducts,
                    meta: {returnName: 'CheckList', isCheckList: true}
                  },
                  {
                    path: 'edit/create',
                    name: 'CheckListCreateProduct',
                    component: EditProduct,
                    meta: {isCreate: true, returnName: 'CheckListEditProducts', isCheckList: true}
                  },
                  {
                    path: 'edit/:productId',
                    name: 'CheckListEditProduct',
                    component: EditProduct,
                    props: true,
                    meta: {returnName: 'CheckListEditProducts', isCheckList: true}
                  },
                ],
              },
              {
                path: 'achievements',
                children: [
                  {
                    path: '',
                    name: 'CheckListEditAchievements',
                    component: EditAchievements,
                    meta: {returnName: 'CheckList', isCheckList: true}
                  },
                  {
                    path: 'edit/create',
                    name: 'CheckListCreateAchievement',
                    component: EditAchievement,
                    meta: {
                      isCreate: true,
                      returnName: 'CheckListEditAchievements',
                      isCheckList: true
                    }
                  },
                  {
                    path: 'edit/:achievementId',
                    name: 'CheckListEditAchievement',
                    component: EditAchievement,
                    props: true,
                    meta: {returnName: 'CheckListEditAchievements', isCheckList: true}
                  },
                ],
              },
              {
                path: 'education',
                children: [
                  {
                    path: '',
                    name: 'CheckListEditEducations',
                    component: EditEducations,
                    meta: {returnName: 'CheckList', isCheckList: true}
                  },
                  {
                    path: 'edit/create',
                    name: 'CheckListCreateEducation',
                    component: EditEducation,
                    meta: {isCreate: true, returnName: 'CheckList', isCheckList: true},
                  },
                  {
                    path: 'edit/:educationType/:educationId',
                    name: 'CheckListEditEducation',
                    component: EditEducation,
                    props: true,
                    meta: {returnName: 'CheckList', isCheckList: true},
                  },
                ],
              },
              {
                path: 'looking-for',
                name: 'CheckListEditLookingFor',
                component: EditLookingFor,
                meta: {returnName: 'CheckList', isCheckList: true},
              },
              {
                path: 'personal-data',
                name: 'CheckListEditPersonalData',
                component: EditPersonalData,
                meta: {returnName: 'CheckList', isCheckList: true},
              },
              {
                path: 'description',
                name: 'CheckListEditDescription',
                component: EditDescription,
                meta: {returnName: 'CheckList', isCheckList: true},
              },
              {
                path: 'contacts',
                name: 'CheckListEditContacts',
                component: EditContacts,
                meta: {returnName: 'CheckList', isCheckList: true},
              },
              {
                path: 'professions',
                name: 'CheckListEditProfessions',
                component: EditProfessions,
                meta: {returnName: 'CheckList', isCheckList: true},
              },
              {
                path: 'niches',
                name: 'CheckListEditNiches',
                component: EditNiches,
                meta: {returnName: 'CheckList', isCheckList: true},
              },
            ],
          },
          {
            path: 'profile/onboarding',
            name: 'Onboarding',
            component: Onboarding,
            meta: {layout: 'EmptyLayout'}
          },
          {
            path: 'profile/edit',
            name: 'EditProfileView',
            children: [
              {path: '', name: 'EditProfile', component: EditProfile},
              {path: 'personal-data', name: 'EditPersonalData', component: EditPersonalData},
              {path: 'description', name: 'EditDescription', component: EditDescription},
              {path: 'contacts', name: 'EditContacts', component: EditContacts},
              {path: 'professions', name: 'EditProfessions', component: EditProfessions},
              {path: 'niches', name: 'EditNiches', component: EditNiches},
              {path: 'looking-for', name: 'EditLookingFor', component: EditLookingFor},
              {
                path: 'achievements',
                children: [
                  {path: '', name: 'EditAchievements', component: EditAchievements},
                  {
                    path: 'edit/create',
                    name: 'CreateAchievement',
                    component: EditAchievement,
                    meta: {isCreate: true}
                  },
                  {
                    path: 'edit/:achievementId',
                    name: 'EditAchievement',
                    component: EditAchievement,
                    props: true
                  },
                ],
              },
              {
                path: 'education',
                children: [
                  {path: '', name: 'EditEducations', component: EditEducations},
                  {
                    path: 'edit/create',
                    name: 'CreateEducation',
                    component: EditEducation,
                    meta: {isCreate: true},
                  },
                  {
                    path: 'edit/:educationType/:educationId',
                    name: 'EditEducation',
                    component: EditEducation,
                    props: true,
                  },
                ],
              },
              {
                path: 'products',
                children: [
                  {path: '', name: 'EditProducts', component: EditProducts},
                  {
                    path: 'edit/create',
                    name: 'CreateProduct',
                    component: EditProduct,
                    meta: {isCreate: true}
                  },
                  {path: 'edit/:productId', name: 'EditProduct', component: EditProduct, props: true},
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'user/:userId',
        children: [
          {
            path: 'profile',
            name: 'AnotherUsersProfile',
            component: AnotherUserProfile,
            props: true,
            meta: {returnName: 'Home', chatName: 'AnotherUsersProfileChat'}
          },
          {
            path: 'chat/:sentUID?',
            name: 'AnotherUsersProfileChat',
            component: UserChat,
            props: true,
            meta: {returnName: 'AnotherUsersProfile', layout: 'EmptyLayout'}
          }
        ]
      },
      {
        path: 'social',
        meta: {navigationTab: 'social'},
        children: [
          {
            path: 'browsing/:userId?',
            name: 'CardHolderSocialBrowsing',
            component: SocialBrowsing,
            props: true,
            meta: {returnName: 'CardHolder'}
          },
          {
            path: 'card-holder',
            children: [
              {
                path: '',
                children: [
                  {
                    path: 'list/:tab?',
                    name: 'CardHolder',
                    component: CardHolder
                  },
                ],
              },
              {
                path: 'search/:filter?',
                name: 'CardHolderSearch',
                meta: {returnName: 'CardHolder', defaultSearch: 'contacts_result'},
                props: true,
                component: UserSearch,
              },
              {
                path: 'user/:userId',
                children: [
                  {
                    path: 'profile',
                    name: 'CardHolderUserProfile',
                    component: AnotherUserProfile,
                    props: true,
                    meta: {returnName: 'CardHolder', chatName: 'CardHolderUserChat'}
                  },
                  {
                    path: 'chat/:sentUID?',
                    name: 'CardHolderUserChat',
                    component: UserChat,
                    props: true,
                    meta: {returnName: 'CardHolderUserProfile', layout: 'EmptyLayout'}
                  },
                ]
              },
            ],
          },
        ],
      },
      {
        path: '/news',
        name: 'News',
        children: [
          {path: '', name: 'NewsHome', component: NewsHome},
          {path: 'news/:id', name: 'NewsId', component: NewsId},
        ],
      },
      {
        path: '/chat',
        meta: {navigationTab: 'chat'},
        children: [
          {
            path: 'list/:tab?',
            name: 'ChatList',
            component: ChatList,
            meta: {layout: 'EmptyHomeLayout'},
          },
          {
            path: 'user/:userId/profile',
            name: 'CardHolderUserProfileChat',
            component: AnotherUserProfile,
            props: true,
            meta: {returnName: 'UserChat', chatName: 'UserChat'}
          },
          {
            path: 'user/:userId/:sentUID?',
            children: [
              {
                path: '',
                name: 'UserChat',
                component: UserChat,
                props: true,
                meta: {returnName: 'ChatList', layout: 'EmptyLayout'}
              },
            ]
          },
        ],
      },
    ],
  },
  {
    path: '/404',
    name: '404',
    meta: {
      requiresAuth: true,
    },
    component: Page404,
  },
  {
    path: '/',
    meta: {
      requiresGuest: true,
    },
    children: [
      {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
      },
      {
        path: '/information-advertising-policy',
        name: 'InformationAdvertisingPolicy',
        component: InformationAdvertisingPolicy,
      },
      {
        path: '/user-agreement',
        name: 'UserAgreement',
        component: UserAgreement,
      },
      {
        path: '/personal-data-processing',
        name: 'PersonalDataProcessing',
        component: PersonalDataProcessing,
      },
    ],
  },
  {
    path: '/welcome',
    name: 'Welcome',
    meta: {
      requiresGuest: true,
    },
    component: Welcome,
  },
  {
    path: '/login',
    name: 'LogIn',
    meta: {
      requiresGuest: true,
    },
    component: LogIn,
  },
  {
    path: '/register',
    name: 'Register',
    meta: {
      requiresGuest: true,
    },
    component: Register,
  },
  {
    preview: '/preview',
    meta: {
      requiresGuest: true,
    },
    children: [
      {
        path: '/event/:eventId',
        name: 'EventPreview',
        component: EventPreviewPage,
        props: true,
        meta: {layout: 'EmptyLayout'}
      },
      {
        path: '/user/:userId',
        name: 'AnotherUsersProfilePreview',
        component: AnotherUsersProfilePreviewPage,
        props: true,
        meta: {layout: 'EmptyLayout'}
      },
      {
        path: '/product/:productId',
        name: 'MarketplaceProductPreview',
        component: MarketplaceProductPreviewPage,
        props: true,
        meta: {layout: 'EmptyLayout'}
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {top: 0}
  },
})

router.beforeEach((to, from, next) => {
  const {isAuthorized, isRestoring, profile} = store.state.user

  if (isAuthorized) {
    if (!profile.onboarding_finished && to.name !== 'Onboarding') {
      next({
        name: 'Onboarding',
      })
      return
    }
    if (profile.onboarding_finished && to.name === 'Onboarding') {
      const after = sessionStorage.getItem('previewAfterOnboarding')
      if (after) {
        sessionStorage.removeItem('previewAfterOnboarding')
        next(JSON.parse(after))
        return
      }
      next('/')
      return
    }
  }

  if (to.meta.requiresGuest) {
    if (isAuthorized) {
      next('/')
    } else {
      next()
    }
    return
  }
  if (to.meta.requiresAuth) {
    if (!isAuthorized && !isRestoring) {
      if (to.name === 'Event') {
        return next({
          name: 'EventPreview',
          params: to.params,
        })
      }
      if (to.name in {AnotherUsersProfile: true, CardHolderUserProfile: true}) {
        return next({
          name: 'AnotherUsersProfilePreview',
          params: to.params,
        })
      }
      if (to.name === 'MarketplaceProducts') {
        return next({
          name: 'MarketplaceProductPreview',
          params: to.params,
        })
      }
    }

    if (isAuthorized) {
      const after = sessionStorage.getItem('previewAfterOnboarding')
      if (after) {
        sessionStorage.removeItem('previewAfterOnboarding')
        next(JSON.parse(after))
        return
      }
      next()
      return
    }

    const query = {}
    if (to.fullPath !== '/') {
      query.returnTo = encodeURIComponent(to.fullPath)
    }
    next({
      name: 'Welcome',
      query,
    })
    return
  }
  next()
})

export {router}
