<template>
  <div class="pageWrapper">
    <head-new
        page_title="Ярмарка"
        :back-action="() => router.push({name: 'Home'})"
    />

    <AnotherUserProfileModalLoading
        :user="state.selectedUser"
        @close="() => {state.selectedUser = null}"
        style="position: relative;z-index: 1002"
    />

    <div class="pageWrapperOverflow">
      <!--      <div class="main_container productsWrapper">-->
      <!--        <div v-if="state.isLoading" class="product" v-for="_ in [1,2,3,4,5]">-->
      <!--          <div class="productInfo">-->
      <!--            <Skeleton-->
      <!--                height="116"-->
      <!--                radius="8"-->
      <!--                full-width-->
      <!--            />-->

      <!--            <div>-->
      <!--              <Skeleton-->
      <!--                  width="180"-->
      <!--                  height="19"-->
      <!--                  :style="{marginBottom: '8px'}"-->
      <!--              />-->
      <!--              <Skeleton-->
      <!--                  variant="text"-->
      <!--                  width="240"-->
      <!--                  height="12"-->
      <!--              />-->
      <!--              <Skeleton-->
      <!--                  variant="text"-->
      <!--                  width="180"-->
      <!--                  height="12"-->
      <!--              />-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->

      <DynamicScroller
          v-if="!state.isLoading && state.products.length"
          class="list main_container"
          :items="state.products"
          :min-item-size="138"
          key-field="id"
          @scroll-end="additionalLoading"
      >
        <!--        :item-size="298"-->
        <!--        :buffer="298"-->
        <template #before>
          <div style="height: 24px"/>
        </template>
        <template #after>
          <div style="height: 24px"/>
        </template>

        <template v-slot="{item: product, index, active}">
          <DynamicScrollerItem
              :item="product"
              :active="active"
              :size-dependencies="[
                  product.id,
                ]"
              :data-index="index"
          >
            <div style="padding-bottom: 12px">
              <div v-if="product.id > 0" class="product">
                <div
                    class="productInfo"
                    @click="state.isSelectedProduct = product"
                    style="cursor: pointer"
                >

                  <div v-if="product.image" class="imgWrapper">
                    <div
                        class="img"
                        :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(product.preview_image))}"
                    />
                  </div>

                  <div>
                    <div class="title">
                      {{ product.title }}
                    </div>
                    <Typography variant="body1" style="white-space: pre-wrap;">
                      {{ product.description }}
                    </Typography>
                  </div>

                  <div class="bottom">
                    <Avatar
                        size="nano"
                        :image="product.created_by?.preview_avatar"
                    />
                    <div
                        style="flex-shrink: 1"
                    >
                      <Typography base="span" variant="body1" color="#D3D2D2">
                        {{ product.created_by.first_name }}
                      </Typography>
                      {{ ' ' }}
                      <Typography base="span" variant="body1" color="#D3D2D2">
                        {{ product.created_by.last_name }}
                      </Typography>
                    </div>
                    <div style="flex-grow: 1"/>

                    <AppButton
                        v-if="store.state.user.profile.id !== product.created_by.id"
                        size="small"
                        @click="state.isSelectedProduct = product"
                    >
                      Получить
                    </AppButton>
                  </div>
                </div>
              </div>
              <div v-else class="product">
                <div class="productInfo">
                  <Skeleton
                      height="116"
                      radius="8"
                      full-width
                  />

                  <div>
                    <Skeleton
                        width="180"
                        height="19"
                        :style="{marginBottom: '8px'}"
                    />
                    <Skeleton
                        variant="text"
                        width="260"
                        height="12"
                        style="margin-right: 12px"
                    />
                    <Skeleton
                        variant="text"
                        width="180"
                        height="12"
                    />
                  </div>
                </div>
              </div>
            </div>
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>

      <BottomDrawer
          :is-shown="state.isSelectedProduct"
          @close="state.isSelectedProduct = null"
          closeButtonOutside
      >
        <div
            v-if="state.isSelectedProduct"
            class="productInfo"
        >
          <div v-if="state.isSelectedProduct.image" class="imgWrapper full">
            <div
                class="img"
                :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(state.isSelectedProduct.image))}"
            />
          </div>

          <div>
            <div class="title">
              {{ state.isSelectedProduct.title }}
            </div>
            <Typography variant="body1" style="white-space: pre-wrap;">
              {{ state.isSelectedProduct.description }}
            </Typography>
          </div>

          <div class="bottom drawer">
            <Avatar
                size="tiny"
                :image="state.isSelectedProduct.created_by?.preview_avatar"
                @click="() => openUser(state.isSelectedProduct.created_by)"
            />
            <div
                style="flex-grow: 1; flex-shrink: 1;"
                @click="() => openUser(state.isSelectedProduct.created_by)"
            >
              <Typography base="span" variant="body1" color="#D3D2D2">
                {{ state.isSelectedProduct.created_by.first_name }}
              </Typography>
              {{ ' ' }}
              <Typography base="span" variant="body1" color="#D3D2D2">
                {{ state.isSelectedProduct.created_by.last_name }}
              </Typography>
            </div>

            <AppButton
                v-if="store.state.user.profile.id !== state.isSelectedProduct.created_by.id"
                size="small"
                @click="applyForProduct(state.isSelectedProduct.created_by, state.isSelectedProduct)"
            >
              Написать
            </AppButton>
          </div>
        </div>
      </BottomDrawer>
    </div>
  </div>
  <!--  </div>-->
</template>

<script setup>
import HeadNew from "@/components/Head.vue"
import {router} from '@/router/router'
import {onBeforeMount, reactive} from 'vue'
import {appAxios} from '@/axios'
import {urlToCssURL} from '@/utils/urlToCssURL'
import {imagesLinkCache} from '@/utils/imagesLinkCache'
import Skeleton from '@/components/UI/Skeleton.vue'
import AppButton from '@/components/UI/AppButton.vue'
import Typography from '@/components/UI/Typography.vue'
import Avatar from '@/components/profile/Avatar.vue'
import BottomDrawer from '@/components/BottomDrawer.vue'
import {useShare} from '@/hooks/useShare'
import {useRoute} from 'vue-router';
import {createSendMessageByType} from '@/utils/createSendMessageByType'
import AnotherUserProfileModalLoading from "@/views/profile/AnotherUserProfileModalLoading.vue"
import store from "../../store/store.js";

const route = useRoute()
const {canShare, share} = useShare()

const props = defineProps({
  productId: {
    type: String,
  }
})

const loadingCount = 10

const state = reactive({
  isLoading: true,
  products: [],
  isSelectedProduct: null,
  selectedUser: null,
  count: 0,
  isAdditionalLoading: false,
  isAdditionalLoadingNeedMore: false,
})

function openUser(user) {
  // if(store.state.user.profile.id === user.id) {
  //
  // } else {
  //   state.selectedUser = user
  // }
  state.selectedUser = user
}

onBeforeMount(async () => {
  const res = await appAxios.products.fetch(loadingCount, 0)

  // const toUp = res.data.results.filter(c => c.id === 14)
  // const rest = res.data.results.filter(c => c.id !== 14)
  // state.products = [...toUp, ...rest]
  state.count = res.data.count
  state.products = res.data.results

  if (props.productId) {
    const found = res.data.find(p => String(p.id) === String(props.productId))
    if (found) {
      state.isSelectedProduct = found
    }
  }

  state.isLoading = false
})

async function additionalLoading() {
  if (state.count > state.products.length) {
    if (state.isAdditionalLoading) {
      state.isAdditionalLoadingNeedMore = true
      return
    }
    state.isAdditionalLoading = true

    state.products.push(...Array(loadingCount).fill(0).map((_, index) => ({
      id: -index - 1,
    })))

    const res = await appAxios.products.fetch(loadingCount, state.products.length - loadingCount)
    state.products = [...state.products.filter(i => i.id >= 0), ...res.data.results]

    state.isAdditionalLoading = false
    if (state.isAdditionalLoadingNeedMore) {
      state.isAdditionalLoadingNeedMore = false
      additionalLoading()
    }
  }
}

function startChat(userId) {
  router.push({name: 'MarketplaceProductUserChat', params: {userId, productId: state.isSelectedProduct.id}})
}

async function applyForProduct(user, product) {
  const uid = createSendMessageByType.product({user, product})
  await router.push({
    name: route.meta.chatName ?? 'UserChat',
    params: {userId: user.id, sentUID: uid},
  })
}

async function submitShare(product) {
  const url = new URL(window.location.href)
  url.pathname = route.fullPath + `/${product.id}`
  await share({
    title: product.title,
    description: product.description,
    url: url.toString(),
  })
}

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.pageWrapper {
  min-height: calc(100dvh);
  max-height: calc(100dvh);
  min-height: calc(100vh);
  max-height: calc(100vh);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.pageWrapperOverflow {
  margin-bottom: 80px;
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  & > * {
    flex-shrink: 0;
  }
}

.productsWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 24px !important;
  overflow-y: auto;
  flex-grow: 1;
  padding-bottom: 34px;
}

.list {
  height: calc(100vh - 56px - 80px);
}

.product {
  background: #222222;
  padding: 12px;
  border-radius: 16px;
}

.productInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .imgWrapper {
    width: 100%;
    height: 116px;
    border-radius: 8px;
    background: #3B3B3B;

    .img {
      width: 100%;
      height: 116px;
      flex-shrink: 0;
      border-radius: 8px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
  }

}

.bottom {
  display: flex;
  gap: 8px;
  padding-top: 16px;
  margin-top: 4px;
  border-top: 1px solid #494949;
  align-items: center;

  & > * {
    flex-shrink: 0;
  }

  &.drawer {
    margin: 0 -16px;
    padding-right: 16px;
    padding-left: 16px;
  }
}

.share {
  margin-top: 20px;
  margin-bottom: 12px;
}

</style>
